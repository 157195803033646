<template>
  <div class="vg_wrapper">
    <el-card>
      <div class="vd_search_group">
        <el-form :inline="true" label-width="120px" size="min" :model="searchForm" ref="searchForm" @keyup.enter.native="getMtqrsNow()">
          <el-row>
            <el-col :md="8">
              <el-form-item label="物料编号:">
                <el-input size="small" v-model.trim="searchForm.mtrl_no" clearable placeholder="请填写物料编号"></el-input>
              </el-form-item>
            </el-col>
            <el-col :md="8">
              <el-form-item label="物料名称:">
                <el-input size="small" v-model.trim="searchForm.mtrl_name" clearable placeholder="请填写物料名称"></el-input>
              </el-form-item>
            </el-col>
            <el-col :md="8">
              <el-form-item label="物料类型:">
                <el-select size="small" v-model="searchForm.mtrl_type" placeholder="请选择物料类型" clearable >
                  <el-option
                      v-for="item in mtrlType"
                      :key="item.id"
                      :label="item.label"
                      :value="item.id">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row> 
          <el-row>
            <el-col :md="8">
              <el-form-item label="潘通色号:">
                <el-input size="small" v-model.trim="searchForm.mtrl_color" clearable placeholder="请填写潘通色号"></el-input>
              </el-form-item>
            </el-col>
            <el-col :md="8">
              <el-form-item label="库位:">
                <el-select size="small" v-model="searchForm.mtqr_bin" placeholder="请选择库位" clearable >
                  <el-option
                      v-for="item in binList"
                      :key="item.id"
                      :label="item.param1"
                      :value="item.id">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :md="8" v-if="userInfo.acct_id==1">
            <el-form-item label="所属账套:" >
              <el-select
                v-model="searchForm.acct_id"
                filterable
                placeholder="请选择所属账套"
                clearable
                size="small"
                @change="getMtqrsNow()"
              >
                <el-option
                  v-for="item in acctList"
                  :key="item.acct_id"
                  :label="item.acct_no"
                  :value="item.acct_id"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          </el-row> 
          <el-row>
            <el-col :md="16">
              <el-form-item label="录入时间:">
                <div class="block vg_mr_32">
                  <el-date-picker
                      size="small"
                      v-model="searchForm.timeValue"
                      type="datetimerange"
                      range-separator="至"
                      start-placeholder="开始日期"
                      end-placeholder="结束日期">
                  </el-date-picker>
                </div>
              </el-form-item>
              <el-form-item>
                <el-button size="small" type="primary" icon="el-icon-search" @click="getMtqrsNow()" class="vg_ml_16">查询</el-button>
                <el-button type="info" size="small" class="vd_export" @click="buttonRefresh()"><i class="el-icon-refresh-right"></i> 刷新</el-button>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <!-- v-if="userInfo.acct_id!=1" -->
      <div class="vg_mtb_16" >
        <el-button v-if="userInfo.acct_id !== 1" type="primary" size="small" @click="addRow()" :disabled="!btn.add"><i class="el-icon-plus"></i> 新增</el-button>
        <el-button v-if="userInfo.acct_id !== 1" type="danger" size="small" @click="doDelete()" :disabled='!btn.delete'><i class="el-icon-delete"></i> 删除</el-button>
        <el-button v-if="userInfo.acct_id !== 1" type="primary" size="small" class="vd_export" @click="print()"><i class="el-icon-s-promotion"></i> 打印</el-button>
        <el-button v-if="userInfo.acct_id !== 1" type="success" size="small" class="vd_export" @click="recycleBin()"><i class="el-icon-refresh"></i> 回收站</el-button>
      </div>
      <el-row>
        <el-col :md="24">
          <el-table ref="multiTable" :data="tableData" @selection-change="handleSelectionChange" @select="selectRows" @select-all="selectRows" border v-loading="loadingFlag">
            <el-table-column v-if="userInfo.acct_id !== 1" type="selection" width="48"  align="center"/>
            <el-table-column label="物料编号" prop="mtrl_no"/>
            <el-table-column label="物料图片" align="center">
              <template slot-scope="scope">
                <el-image 
                  v-if="scope.row.imge_url"
                  class="vd_elimg"
                  :src="formatPic(scope,'s')"
                  fit="fill">
                </el-image>
                  <!-- :src="helper.picUrl(scope.row.imge_url,'m')"   -->
                <span v-else class="vg_9f9a9a">暂无</span>
              </template>
            </el-table-column>
            <el-table-column label="二维码图片" align="center">
              <template slot-scope="scope">
                <el-image
                  class="vd_elimg"
                   v-if="scope.row.mtqr_url"
                  :src="formatPic1(scope,'l')" 
                  fit="fill">
                </el-image>
                <span v-else class="vg_9f9a9a">暂无</span>
              </template>
            </el-table-column>
            <el-table-column label="物料名称" prop="mtrl_name" />
            <el-table-column label="物料类型" prop="mtrl_type"  :formatter="formatLeavType" />
            <el-table-column label="潘通色号" prop="mtrl_color">
              <template slot-scope="scope">
                <span v-if="scope.row.mtrl_color">
                  <div class="vd_dis">
                    <div :class="{'vd_div':scope.row.colr_html}" :style="{'background-color': scope.row.colr_html}"></div>
                    <span>{{ scope.row.mtrl_color}}</span> 
                  </div>
                </span>
                <span v-else class="vg_9f9a9a">暂无</span>
              </template>
            </el-table-column>
            <el-table-column label="门幅" prop="mtrl_width" :formatter="formatLeavwidth" /> 
             
            <el-table-column label="数量" prop="mtqr_num">
              <template slot-scope="scope">
                <div v-if="scope.row.mtqr_num">
                  {{ scope.row.mtqr_num | formatMtqrNum}}
                </div>
                <div v-else class="vg_9f9a9a">暂无</div>
              </template>
            </el-table-column>
            <el-table-column label="物料单位" prop="mtrl_unit">
              <template slot-scope="scope">
                <span v-if="scope.row.mtrl_unit">
                  {{ scope.row.mtrl_unit}}
                </span>
                <span v-else class="vg_9f9a9a">暂无</span>
              </template>
            </el-table-column>
            <el-table-column label="库位" prop="mtqr_bin_no"  >
              <template slot-scope="scope">
                <span v-if="scope.row.mtqr_bin_no">
                  {{ scope.row.mtqr_bin_no}}
                </span>
                <span v-else class="vg_9f9a9a">暂无</span>
              </template> 
            </el-table-column>
            <el-table-column label="物料毛高">
              <template slot-scope="scope">
                <div v-if="scope.row.mtrl_thick || scope.row.mtrl_thick===0">
                  {{ scope.row.mtrl_thick | formatMtqrMtrlThick}}毫米
                </div>
                <div v-else class="vg_9f9a9a">暂无</div>
              </template>
            </el-table-column>
            <el-table-column label="录入人" prop="stff_name" show-overflow-tooltip :formatter="helper.personCombina"/>
            <el-table-column label="录入时间" prop="create_time" :formatter="formatDate" />
            <el-table-column label="所属账套" prop="acct_no" v-if="userInfo.acct_id===1"/>
            <el-table-column label="所属公司" prop="cptt_name" show-overflow-tooltip  v-if="userInfo.acct_id==1"/>
          </el-table>
        </el-col>
      </el-row>
      <el-dialog
        title=""
        :visible.sync="dialogVisible"
        width="30%">
        <mtqrPrint :mtqrPrint="mtqrPrint" @printCancel="printCancel"></mtqrPrint>
      </el-dialog>
      <el-dialog
        title=""
        :visible.sync="recycleBinFlag"
        :before-close="handleClose"
        width="70%">
        <mtqrListHui ref="mtqrListHui" @confirmIn="confirmIn"></mtqrListHui>
      </el-dialog>
      <el-row>
        <el-col :md="24">
          <pubPagination :totalPage = 'totalPage' @changePageSearch = "changePageSearch" ref="pubPagination"></pubPagination>
        </el-col>
      </el-row>
    </el-card>
  </div>
</template>

<script>
import {get,post} from "@api/request";
import {mtqrAPI} from "@api/modules/mtqr"
import {optnAPI} from "@api/modules/optn"
import pubPagination from "@/components/common/pubPagination";
import mtqrPrint from "./TabChild/Componet/MtqrPrint.vue"
import mtqrListHui from "./TabChild/Componet/MtqrListHui.vue";
// import {getLodop} from '../../../plugins/LodopFuncs'
import helper from "@assets/js/helper.js"
import { acctAPI } from "@api/modules/acct";


export default {
  name: "MtqrList",
  components:{
    pubPagination,
    mtqrPrint,
    mtqrListHui,
  },
  data() {
    return {
      tableData:[],
      searchForm:{
        mtrl_no:null,
        mtqr_bin:null,
        mtrl_color:null,
        mtrl_name:null,
        mtrl_type:null,
        acct_id:2,
        timeValue:{
          startTime:null,
          endTime:null
        },
      },
      totalPage:0,
      btn:{},
      loadingFlag: true,
      multiSelection: [],
      currentPage:1,
      binList:[],
      mtrlType:[
        {id:0,label:'原面料'},
        {id:1,label:'辅料'},
        {id:2,label:'包材'},
      ],
      dialogVisible:false,
      mtqrPrint:[],
      recycleBinFlag:false,
      userInfo:null,
      acctList: [],
    }
  },
  created() {
    this.initData()
  },
  filters:{
    formatMtqrNum(row){
      return helper.haveFour(row)
    },
    formatMtqrMtrlThick(row){
      return helper.reservedDigits(row)
    }
  },
  watch:{
    $route(to,from){
      if(from.path === '/mtqr_add' ){
        this.initData()
      }
    },
  },
  methods: {
    initData(){
      this.loadingFlag = true
      this.getBinList()
      this.getUserInfo()
     if(this.userInfo.acct_id==1){
       this.getAcct()
     }else{
       this.getMtqrsList()
     }
      
    },
    getUserInfo(){
      this.userInfo = this.$cookies.get('userInfo')
    },
     //获取审批账套
    getAcct() {
      get(acctAPI.getAllAcctsV1)
        .then((res) => {
          if (res.data.code == 0) {
            this.acctList = res.data.data;
            setTimeout(()=>{
              this.getMtqrsList()
            },500)
            return;
          }
          return this.$message.error(res.data.msg);
        })
        .catch((res) => {
          let mg = res.data.msg;
          let tp = "error";
          this.$message({ message: mg, type: tp });
        });
    },
    // 获取库位信息
    getBinList(){
      get(optnAPI.getAllContent,{perm_id : 10006})
      .then(res=>{
        if(res.data.code === 0){
          this.binList = res.data.data
        }else{
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({message:mg,type:tp});
        }
      })
      .catch(res=>{
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({message:mg,type:tp});
          })
    },
    // 获取二维码信息
    getMtqrsList(){
      if(this.userInfo.acct_id!=1){
        this.searchForm.acct_id = null
      }
      const timeNewVal = this.helper.getTime(this.searchForm.timeValue)
      get(mtqrAPI.getMtqrs,{
        mtrl_type:this.searchForm.mtrl_type,
        mtrl_no:this.searchForm.mtrl_no,
        mtqr_bin:this.searchForm.mtqr_bin,
        mtrl_name:this.searchForm.mtrl_name,
        mtrl_color:this.searchForm.mtrl_color,
        start_time: timeNewVal.startTime,
          end_time: timeNewVal.endTime,
          page_no: this.currentPage,
          acct_id:this.searchForm.acct_id
      })
      .then(res=>{
        if(res.data.code === 0){
          this.loading = false;
          this.tableData = res.data.data.list;
          this.totalPage = res.data.data.total;
          this.btn = res.data.data.btn;
          setTimeout(() => {
            this.loadingFlag = false
          }, 500);
        } else {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({message:mg,type:tp});
        }
      })
      .catch(res=>{
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({message:mg,type:tp});
          })
    },
    // 刷新
    buttonRefresh(){
      this.searchForm = {
        mtrl_no:null,
        mtqr_bin:null,
        mtrl_color:null,
        mtrl_name:null,
        mtrl_type:null,
        timeValue:{
          startTime:null,
          endTime:null
        },
      };
      this.loadingFlag = true
      this.currentPage = 1
      this.initData()
      this.$refs.pubPagination.currentPage = 1
    },
    // 关闭打印页面
    printCancel(){
      this.dialogVisible = false
    },
    // 回收站打开页面
    recycleBin(){
      this.recycleBinFlag = true
    },
    // 关闭回收页面
    confirmIn(){
      this.recycleBinFlag = false
      this.initData()
    },
    // 关闭回收页面清空内容
    handleClose(){
      this.recycleBinFlag = false
      this.$refs.mtqrListHui.refesh()
    },
    // 打印
    print(){
      if(this.multiSelection.length>0){
        if(this.multiSelection.length<=10){
          this.dialogVisible = true;
          this.mtqrPrint = this.multiSelection
        }else{
          this.$message({message:'最多只能打印10条',type:'warning'})
        }
      }else{
        this.$message({message:'至少选择一条数据',type:'warning'})
      }
      
      // const LODOP = getLodop()
      // this.mtqrPrint = []
      // if(this.multiSelection.length>0){
      //   // this.dialogVisible = true;
      //   // this.mtqrPrint = this.multiSelection
      //   LODOP.PRINT_INIT('')
      //   // LODOP.SET_PRINT_PAGESIZE(1,'50px',80*this.multiSelection.length+0.3*(this.multiSelection.length)+'px',"")
      //   LODOP.SET_PRINT_PAGESIZE(1,'50mm','80mm','')
      //   LODOP.SET_PRINT_STYLE('Alignment','2')
      //   for(let i=0;i<this.multiSelection.length;i++){
      //     let temp = this.multiSelection[i].mtqr_url
      //     LODOP.SET_PRINT_STYLE("FontSize",10)
      //     //LODOP.ADD_PRINT_HTM(0,0,300,100,"<img width='140px' height='140px' src='http://192.168.1.38:8885/file"+temp+".jpg'/>")
      //     LODOP.ADD_PRINT_IMAGE(80*(i)+5+'mm','5mm','40mm','40mm',"<img width='140px'height='140px' src='http://47.97.37.94/file"+temp+".jpg'/>")
      //     LODOP.ADD_PRINT_TEXT(80*(i)+45+'mm','0mm','50mm','10mm',"物料编号:"+this.multiSelection[i].mtrl_no)
      //     LODOP.ADD_PRINT_TEXT(80*(i)+53+'mm','0mm','50mm','10mm',"物料名称:"+this.multiSelection[i].mtrl_name)
      //     LODOP.ADD_PRINT_TEXT(80*(i)+61+'mm','0mm','50mm','10mm',"潘通色号:"+this.multiSelection[i].mtrl_color)
      //     LODOP.ADD_PRINT_TEXT(80*(i)+69+'mm','0mm','50mm','10mm',"库位名称:"+this.multiSelection[i].mtqr_bin_no)
      //   }
      //   // LODOP.SET_PRINT_COPIES(this.multiSelection.length)
      //   LODOP.PREVIEW()
      // }else{
      //   this.$message({type:'warning',message:'请至少选择一条数据！'})
      // }
    },
    // 查询方法
    getMtqrsNow(){
      this.loadingFlag = true
      this.$refs.pubPagination.currentPage = 1
      this.currentPage = 1
      this.initData()
    },
    // 状态查询
    getStatusVal(val){
      this.searchForm.status = val
    },
    // 多选
    handleSelectionChange(val){
      this.multiSelection = val
    },
    // 判断是否可以删除
    selectRows(selection){
      this.btn.delete = this.helper.isDelete(selection)
    },
    // //新增
    addRow(){
      const permId = this.$route.query.perm_id
      this.jump(`/mtqr_add?perm_id=${permId}`)
    },
    // 分页查询
    changePageSearch(val){
      this.currentPage = val
      this.initData()
    },
    // 物料类型
    formatLeavType(row){
      if(row.mtrl_type === 0){
        return '原面料'
      } else if(row.mtrl_type === 1){
        return '辅料'
      } else if(row.mtrl_type === 2){
        return '包材'
      } else if(row.mtrl_type === 3){
        return '加工面料'
      }
    },
    formatLeavwidth(row){
      return helper.haveFour(row.mtrl_width) + '米'
    },
    // 时间转换
    formatDate(row) {
      return this.helper.toStringDate(row.create_time)
    },
    // 未选中提示
    doDelete(){
      if(this.multiSelection.length === 0){
        this.$message({type:'warning',message:'请至少选择一条数据！'})
      } else {
        this.mBox()
      }
    },
    //物料信息list删除提示
    mBox() {
      this.$confirm('确定删除？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        const ids = []
        this.multiSelection.map((item)=>{
          ids.push(item.mtqr_id)
        })
        post(mtqrAPI.deleteMtqrByIds,{'mtqr_id_list':ids})
        .then(res=>{
          let mg = res.data.msg;
          if(res.data.code === 0){
            let tp = 'success';
            this.$message({message:mg,type:tp});
            if(ids.length === this.tableData.length){
                if(this.currentPage > 1){
                  this.currentPage = this.currentPage -1
                  this.$refs.pubPagination.currentPage = this.currentPage
                }
              }
            this.initData()
          } else {
            let tp = 'error';
            this.$message({message:mg,type:tp});
            this.initData()
          }
        })
        .catch(res=>{
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({message:mg,type:tp});
          this.initData()
        })
      }).catch(() => {
        let mg = '取消删除！'
        let tp = 'info'
        this.$message({message:mg,type:tp})
      })
    },
     // 图片类型
    formatPic(scope, type) {
      let updateTime = JSON.parse(JSON.stringify(new Date().getTime()))
      return this.helper.picUrl(
        scope.row.imge_url,
        type,
        updateTime,
        scope.row.imge_source
      );
    },
    formatPic1(scope, type) {
      let updateTime = JSON.parse(JSON.stringify(new Date().getTime()))
      return this.helper.picUrl(
        scope.row.mtqr_url,
        type,
        updateTime,
        0
      );
    },
  }
}
</script>

<style scoped lang="scss">
.vd_elimg {
  width: 60px;
  height: 60px;
  margin-top: 5px;
}
::v-deep .el-checkbox__inner{
      border: 1px solid #080808;
}  
.vd_dis{
  display: flex;
}
.vd_div{
  width: 40px;
  height: 20px;
  border: none;
  margin-right: 5px;
}
</style>
